const SET_STOCKCONFIGURATIONS = (state, stockConfigurations) => {
  state.stockConfigurations = stockConfigurations
}

const SET_LOGS = (state, logs) => {
  state.logs = logs
}

const SET_STOCKCONFIGURATION_CURRENT = (state, stockConfiguration) => {
  state.stockConfigurationCurrent = stockConfiguration;
}

export default {
  SET_STOCKCONFIGURATIONS,
  SET_LOGS,
  SET_STOCKCONFIGURATION_CURRENT
}
