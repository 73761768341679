import moment from 'moment'

const getStatusClass = (status) => {
    let statusParams = {
        error: "statusError",
        integrated: "statusIntegrated",
        scheduled: "statusScheduled",
        processing: "statusProcessing",
        canceled: "statusCanceled",
        deleted: "statusCanceled",
        complete: "statusComplete",
        imported: "statusImported",
        delivered: "statusDelivered",
        dispatched: "statusDispatched",
        holded: "statusHolded",
        presales: "statusIntegrated",
        faturado: "statusFaturado",
        waitingTransfer: "statusScheduled",
        waitingCancel: "statusScheduled",
        picking: "statusPicking",
        "em-remessa": "statusIntegrated"
    };

    return `status ${statusParams[status]}` || "status statusDefault";
}

const ordersToTable = state => {
    return state.orders.map(o => {
        return {
            Id : o.Id,
            Number : o.Number,
            PurchaseDate : moment.utc(o.PurchaseDate).format("DD/MM/YYYY HH:mm"),
            EcommerceName : o.EcommerceName,
            Channel : o.Channel,
            WarehouseCode: o.ProductWarehouseCode ? o.ProductWarehouseCode : o.WarehouseCode,
            BplId : o.BplID,
            ImportedDate : moment.utc(o.ImportedDate).format("DD/MM/YYYY HH:mm"),
            Status : {
                Name : o.StatusAliasName,
                Class : getStatusClass(o.StatusBahnReference),
                BahnReference : o.StatusBahnReference
          },
          TargetERP:o.TargetERP,
            Documents : {
                Number: o.Number,
                DocEntry: o.DocEntry,
                DocEntryNf: o.DocEntryNf,
                DocEntryPicking: o.DocEntryPicking,
                DocEntryCR: o.DocEntryCR,
                DocEntryAdto: o.DocEntryAdto,
                DocEntryNfTransfer: o.DocEntryNfTransfer,
                DocEntryPurchaseDeliveryTransfer: o.DocEntryPurchaseDeliveryTransfer,
                DocEntryPurchaseInvoiceTransfer: o.DocEntryPurchaseInvoiceTransfer,
            }
        }
    });
};

export default {
    ordersToTable
}
