import axios from 'axios';
import store from '@/store';
import Vue from "vue";

const endpointBahngleis = localStorage.getItem('endpointBahngleis');

class Bahngleis {
    constructor(showLoad = true) {
        var instance = axios.create({
            timeout: 100000,
            baseURL: endpointBahngleis,
            json: true
        });

        instance.interceptors.request.use(
            config => {
                if (showLoad) store.commit('loading', true);

                config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
                return config;
            },
            error => {
                Promise.reject(error)
            }
        );

        instance.interceptors.response.use(
            response => {
                store.commit('loading', false);
                return response;
            },
            error => {
                store.commit('loading', false);
                return Promise.reject(error);
            }
        );

        return instance;
    }
}

export default Bahngleis;
