const SET_FRANCHISES = (state, franchises) => {
  state.franchises = franchises
}

const SET_LOGS = (state, logs) => {
  state.logs = logs
}

const SET_FRANCHISE_CURRENT = (state, franchise) => {
  state.franchiseCurrent = franchise;
}

const SET_WAREHOUSES = (state, warehouses) => {
  state.warehouses = warehouses;
}

export default {
  SET_FRANCHISES,
  SET_LOGS,
  SET_FRANCHISE_CURRENT,
  SET_WAREHOUSES
}
