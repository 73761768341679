import Bahngleis from "@/apis/bahngleis";
const bahngleis = new Bahngleis();

const getStockConfigurations = async ({ rootState, commit }, params) => {
  params = params || {};
  params = Object.assign(params, { ecommerceName: rootState.ecommerce.Name });
  return await bahngleis.get(`StockConfiguration/GetPaginated`, { params: params }).then(response => {
    commit('SET_STOCKCONFIGURATIONS', response.data.Items);
    return response;
  });
}

const removeStockConfiguration = async ({ }, id) => {
  return await bahngleis
    .delete(`/StockConfiguration/${id}`)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const getStockConfigurationLog = async ({ commit }, id) => {
  return await bahngleis
    .get(`/LogAction/getlogsofentity?identity=${id}&entity=StockConfiguration`)
    .then(response => {
      commit('SET_LOGS', response.data);
    });
}

const saveStockConfiguration = async ({ rootState }, payload) => {
  payload = Object.assign(payload, { ecommerceName: rootState.ecommerce.Name });
  return await bahngleis
    .post(`StockConfiguration/`, payload);
}

const updateStockConfiguration = async ({ }, payload) => {
  return await bahngleis
    .put(`StockConfiguration/`, payload);
}

export default {
  getStockConfigurations,
  removeStockConfiguration,
  saveStockConfiguration,
  updateStockConfiguration,
  getStockConfigurationLog,
}
