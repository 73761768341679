const ProductsChildren = [
  {
    link: {
      name: "Cadastros",
      icon: "tim-icons icon-paper",
      path: "/product/list",
    },
    permission: '22_r',
  },
  {
    link: {
      name: "Atributos",
      icon: "tim-icons icon-bullet-list-67",
      path: "/product/attributes",
    },
    permission: '22_r',
  },
  {
    link: {
      name: "Grupos de Atributos",
      icon: "tim-icons icon-components",
      path: "/attributes/group",
    },
    permission: '22_r',
  },
  {
    link: {
      name: "Detalhes da Integração",
      icon: "tim-icons icon-zoom-split",
      path: "/product/integration-logs",
    },
    permission: '22_r',
  },
  {
    link: {
      name: "Logs de Importação",
      icon: "tim-icons icon-sound-wave",
      path: "/product/file-imports",
    },
    permission: '22_r',
  },
  {
    link: {
      name: "Marca",
      icon: "tim-icons icon-tag",
      path: "/Branding",
    },
    permission: '03_r',
  }
];

const ProductsVulcabrasChildren = [
  {
    link: {
      name: "Mapeamento",
      icon: "tim-icons icon-paper",
      path: "/Product/DeParaProductSync",
    },
    permission: '22_r',
  },
  {
    link: {
      name: "Importar Mapeamento",
      icon: "tim-icons icon-cloud-upload-94",
      path: "/Product/ImportSku",
    },
    permission: '15_r',
  },
  {
    link: {
      name: "Exportar Imagens",
      icon: "tim-icons icon-components",
      path: "/Export/ExportProductsImages",
    },
    permission: '10_r',
  },
  {
    link: {
      name: "Atualização Manual",
      icon: "tim-icons icon-pencil",
      path: "/product/ManualImport",
    },
    permission: '21_r',
  },
  {
    link: {
      name: "Marca",
      icon: "tim-icons icon-tag",
      path: "/Branding",
    },
    permission: '03_r',
  }
];


const DefinitionOfEcommerceChildren = [
  {
    link: {
      name: "E-commerce",
      icon: "tim-icons icon-bag-16",
      class: "sidebar-normal",
      path: "/configuration/Ecommerce",
    },
    permission: '09_r',
  },
  {
    link: {
      name: "Status",
      icon: "tim-icons icon-molecule-40",
      path: "/Status",
    },
    permission: '40_r',
  },
  {
    link: {
      name: "Modalidades de Envio",
      icon: "el-icon-truck",
      path: "/Shippingtype",
    },
    permission: '39_r',
  }
];

const MappingERPChildren = [
  {
    link: {
      name: "Geral",
      icon: "tim-icons icon-settings",
      class: "sidebar-normal",
      path: "/configuration/geral",
    },
    permission: '13_r',
  },
  {
    link: {
      name: "Estoque Inteligente",
      icon: "tim-icons el-icon-box",
      class: "sidebar-normal",
      path: "/configuration/stock",
    },
    permission: '00_c', //Está apenas para admin até aprovar a proposta BAHN-5012
  },
  {
    link: {
      name: "Organizações",
      icon: "tim-icons icon-vector",
      class: "sidebar-normal",
      path: "/Organizations",
    },
    permission: '13_r',
  },
  {
    link: {
      name: "Utilizações",
      icon: "el-icon-guide",
      class: "sidebar-normal",
      path: "/Usage",
    },
    permission: '43_r',
  },
  {
    link: {
      name: "Depósitos",
      icon: "tim-icons icon-compass-05",
      path: "/configuration/warehouse",
    },
    permission: '44_r',
  },
  {
    link: {
      name: "Lista de Preço",
      icon: "tim-icons icon-notes",
      path: "/PriceList",
    },
    permission: '34_r',
  },
  {
    link: {
      name: "Métodos de Envio",
      icon: "tim-icons icon-bus-front-12",
      path: "/ShippingMethods",
    },
    permission: '38_r',
  },
  {
    link: {
      name: "Formas de Pagamento",
      icon: "tim-icons icon-wallet-43",
      path: "/PaymentMethods",
    },
    permission: '31_r',
  },
  {
    link: {
      name: "Vendedores",
      icon: "tim-icons icon-badge",
      path: "/Seller",
    },
    permission: '37_r',
  },
  {
    link: {
      name: "Grupo de Clientes",
      icon: "tim-icons icon-single-02",
      path: "/GroupCode",
    },
    permission: '14_r',
  },
  {
    link: {
      name: "Endereços",
      icon: "tim-icons icon-map-big",
      path: "/County",
    },
    permission: '06_r',
  },
  {
    link: {
      name: 'Fulfillment',
      icon: 'tim-icons icon-delivery-fast',
      path: '/configuration/FulfillmentConfiguration',
    },
    permission: '12_r',
  },
  {
    link: {
      name: 'Eventos',
      icon: 'tim-icons icon-send',
      path: '/configuration/CustomEvent',
    },
    permission: '07_r',
  },
  {
    link: {
      name: 'Centro de Custo',
      icon: 'tim-icons el-icon-money',
      path: '/configuration/CostCenterConfiguration',
    },
    permission: '05_r',
  }
];

const ManageDocumentsChildren = [
  {
    link: {
      name: 'Documentos',
      icon: 'el-icon-files',
      path: '/configuration/ManageDocuments',
    },
    permission: '00_c',
  },
  {
    link: {
      name: 'Observação',
      icon: 'el-icon-edit-outline',
      path: '/configuration/ObservationDocument',
    },
    permission: '27_r',
  },
];

const HistoryChildren = [
  {
    link: {
      name: 'Captação Manual',
      icon: 'tim-icons icon-cloud-download-93',
      path: '/Logs/ManualCaptureLog',
    },
    permission: '20_r',
  },
  {
    link: {
      name: 'Estoque',
      icon: 'el-icon-box',
      path: '/Logs/LogStock',
    },
    permission: '41_r',
  },
  {
    link: {
      name: 'Preços',
      icon: 'tim-icons icon-coins',
      path: '/Logs/LogPrice',
    },
    permission: '33_r',
  },
  {
    link: {
      name: 'Pedidos',
      icon: 'el-icon-sold-out',
      path: '/Logs/LogOrder',
    },
    permission: '18_r',
  },
  {
    link: {
      name: 'ações de usuário',
      icon: 'el-icon-user',
      path: '/Logs/LogAction',
    },
    permission: '17_r',
  },
  {
    link: {
      name: 'Logs de Integração',
      icon: 'tim-icons icon-sound-wave',
      path: '/message',
    },
    permission: '16_r',
  },
];

const IntegrationDefinitionsChildren = [
  {
    link: {
      name: 'Regras de Pedido',
      icon: 'el-icon-set-up',
      path: '/CustomField',
    },
    permission: '30_r',
  },
  {
    link: {
      name: 'Autenticações',
      icon: 'tim-icons icon-lock-circle',
      path: '/Configurations',
    },
    permission: '02_r',
  },
  {
    link: {
      name: 'Addons',
      icon: 'tim-icons icon-puzzle-10',
      path: '/configuration/Addon',
    },
    permission: '01_r',
  },
  {
    link: {
      name: "Franquias",
      icon: "tim-icons icon-vector",
      class: "sidebar-normal",
      path: "/Franchises",
    },
    permission: '44_r',
  },
  {
    link: {
      name: 'Mapeamentos ERP',
      icon: 'tim-icons icon-settings-gear-63',
      children: [],
    },
    permission: '24',
  },
  {
    link: {
      name: 'Documentos ERP',
      icon: 'tim-icons icon-single-copy-04',
      children: [],
    },
    permission: '23',
  },
  {
    link: {
      name: 'Canais de Venda',
      icon: 'tim-icons icon-tv-2',
      path: '/configuration/ManageSaleChannels',
    },
    permission: '35_r',
  },
];

const NotificationsChildren = [
  {
    link: {
      name: "Agendamentos",
      icon: "el-icon-time",
      path: "/schedules",
    },
    permission: '36_r',
  },
  {
    link: {
      name: "Notificações",
      icon: "tim-icons icon-bell-55",
      class: "sidebar-normal",
      path: "/configuration/NotificationEmail",
    },
    permission: '26_r',
  }
];

export default {
  DefinitionOfEcommerceChildren,
  MappingERPChildren,
  ProductsChildren,
  ProductsVulcabrasChildren,
  ManageDocumentsChildren,
  HistoryChildren,
  IntegrationDefinitionsChildren,
  NotificationsChildren
}
