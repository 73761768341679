const stockConfigurationsToTable = state => {
  const stockConfigurations = state.stockConfigurations.sort((a, b) => a.Id - b.Id).map(stockConfiguration => {
    return {
      id: stockConfiguration.Id,
      sku: stockConfiguration.Sku,
      intelligentSafetyQuantity: stockConfiguration.IntelligentSafetyQuantity,
      basicSafetyQuantity: stockConfiguration.BasicSafetyQuantity,
      maximumQuantity: stockConfiguration.MaximumQuantity,
      active: stockConfiguration.Active
    };
  });

  return stockConfigurations;
};

export default {
  stockConfigurationsToTable
}
