<template>
  <div class="row">
    <div class="col-8">
      <div class="d-flex justify-content-center justify-content-between flex-wrap">
        <div></div>
        <div>
          <button class="custom-btn-save save-configuration" @click="Save()">Salvar</button>
        </div>
      </div>
    </div>
    <div class="col-8">
      <card type="tasks">
        <div class="row">
          <div class="col-8">
            <h4 id="v-step-16">Autenticação ERP</h4>
            <p style="color: red;">ATENÇÃO: A configuração de Autenticação ERP é por Ecommerce, revise todas lojas.</p>
            <base-input class="col-12" label="URL" v-model="EcommerceConfiguration.Erp.Url" />
            <base-input class="col-12" v-if="isSapBusinessOne" label="Banco de dados"
              v-model="EcommerceConfiguration.Erp.Database" />
            <base-input class="col-12" v-else label="Sap Client" v-model="EcommerceConfiguration.Erp.SapClient" />
            <base-input class="col-12" label="Usuário" v-model="EcommerceConfiguration.Erp.User" />
            <base-input class="col-12 bold-label" label="Nova senha">
              <el-input v-model="EcommerceConfiguration.Erp.Password" type="password" placeholder="Senha"
                show-password />
            </base-input>
            <el-tooltip content="Apenas as alterações salvas serão consideradas no teste." effect="light"
              :open-delay="300" placement="top">
              <base-button class="mt-3" native-type="submit" type="primary" @click="testErpConnection()">
                Testar
              </base-button>
            </el-tooltip>
          </div>
          <div class="col-4">
            <div class="d-flex justify-content-center justify-content-between flex-wrap">
              <div>
                <img class="icon-erp" :src="getPathImage()" alt="ERP" />
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
    <div class="col-8" v-if="isSapBusinessOne">
      <card type="tasks">
        <div class="row">
          <h4>Autenticação XS Engine</h4>
          <base-input>
            <el-tooltip content="Em algumas versões da XS Engine, pode ocorrer a ausência dos pacotes necessários para realizar o
            teste de login. Nesses casos, proceda com o acesso à tela de login para realizar o teste manualmente."
              effect="light" :open-delay="100" placement="top">
              <a :href="urlFormatter(EcommerceConfiguration.Erp.UrlXS)" target="_blank"
                class="!important custom-btn-primary" icon>
                <i class="material-icons">open_in_browser</i>
              </a>
            </el-tooltip>
          </base-input>
        </div>
        <base-input class="col-12" label="URL XS Engine" v-model="EcommerceConfiguration.Erp.UrlXS" />
        <base-input class="col-12" label="Usuário XS Engine" v-model="EcommerceConfiguration.Erp.UserXS" />
        <base-input class="col-12 bold-label" label="Nova senha">
          <el-input v-model="EcommerceConfiguration.Erp.PasswordXS" type="password" placeholder="Senha XS Engine"
            show-password />
        </base-input>
        <el-tooltip content="Apenas as alterações salvas serão consideradas no teste." effect="light" :open-delay="300"
          placement="top">
          <base-button class="mt-3" native-type="submit" type="primary" @click="testXSodataConnection()">
            Testar
          </base-button>
        </el-tooltip>
      </card>
    </div>
    <div class="col-8">
      <card type="tasks">
        <h4 id="v-step-17">Autenticação E-Commerce</h4>
        <base-input class="col-12" label="Nome" v-model="EcommerceConfiguration.Name" readonly />
        <base-input class="col-12" label="URL" v-model="EcommerceConfiguration.Url" />
        <base-input class="col-12" label="Usuário" v-model="EcommerceConfiguration.User" />
        <base-input class="col-12" label="Code" v-model="EcommerceConfiguration.Code" />
        <base-input class="col-12" label="Id da Loja" v-model="EcommerceConfiguration.EcommerceStoreId" />
        <base-input class="col-12 bold-label" label="Nova senha">
          <el-input v-model="EcommerceConfiguration.Password" type="password" placeholder="Senha" show-password />
        </base-input>
        <el-tooltip content="Apenas as alterações salvas serão consideradas no teste." effect="light" :open-delay="300"
          placement="top">
          <base-button class="mt-3" native-type="submit" type="primary" @click="testEcommerceConnection()">
            Testar
          </base-button>
        </el-tooltip>
      </card>
    </div>
    <div class="col-8" v-if="isSapBusinessOne">
      <card type="tasks">
        <h4>Autenticação Roter</h4>
        <base-input class="col-12" label="Usuário" v-model="EcommerceConfiguration.Erp.UserRoter" />
        <base-input class="col-12" label="URL" v-model="EcommerceConfiguration.Erp.UriRoter" />
        <base-input class="col-12 bold-label" label="Nova senha">
          <el-input v-model="EcommerceConfiguration.Erp.PasswordRoter" type="password" placeholder="Senha"
            show-password />
        </base-input>
      </card>
    </div>
    <div class="col-8" v-if="isSapBusinessOne">
      <div class="d-flex justify-content-center justify-content-between flex-wrap">
        <card type="tasks">
          <h4>String de Conexão</h4>
          <base-input class="col-12" label="String de Conexão" v-model="EcommerceConfiguration.Erp.ConnectionString" />
        </card>
      </div>
    </div>
    <div class="col-8" v-if="isSapBusinessOne">
      <card type="tasks">
        <h4>Autenticação Sigur</h4>
        <base-input class="col-12" label="URL API de Tracking"
          v-model="EcommerceConfiguration.Erp.SigurTrackerApiURL" />
        <base-input class="col-12" label="URL API de Login" v-model="EcommerceConfiguration.Erp.SigurAuthURL" />
        <base-input class="col-12" label="Chave de API" v-model="EcommerceConfiguration.Erp.SigurAccessKey" />
        <base-input class="col-12 bold-label" label="Nova chave secreta">
          <el-input v-model="EcommerceConfiguration.Erp.SigurSecretKey" type="password" placeholder="Chave Secreta"
            show-password />
        </base-input>
      </card>
    </div>
    <div class="col-8">
      <div class="d-flex justify-content-center justify-content-between flex-wrap">
        <card type="tasks">
          <h4>CISS</h4>
          <base-input class="col-12" label="URL" v-model="EcommerceConfiguration.Erp.UrlCISS" />
          <base-input class="col-12" label="Token" v-model="EcommerceConfiguration.Erp.TokenCISS" />
        </card>
      </div>
    </div>
    <div class="col-8">
      <UpdateLog :dataLog="dataLog" />
    </div>
  </div>
</template>

<script>

import UpdateLog from "../../components/UpdateLog.vue";
import { mapState } from 'vuex';
import constants from "@/util/constants";
import { EventBus } from "@/eventBus";
import swal from "sweetalert2";

export default {
  computed: {
    ...mapState(['ecommerce']),
    ...mapState("health", ["displayRestrictedData"])
  },
  components: {
    UpdateLog
  },
  data() {
    return {
      dataLog: [],
      ErpPasswordType: "password",
      ErpPasswordIcon: "fa fa-eye",
      ErpPasswordXSType: "password",
      ErpPasswordRoterType: "password",
      ErpPasswordRoterIcon: "fa fa-eye",
      ErpPasswordXSIcon: "fa fa-eye",
      EcommercePasswordType: "password",
      EcommercePasswordIcon: "fa fa-eye",
      txtCertified: "",
      SigurSecretKeyType: "password",
      SigurSecretKeyIcon: "fa fa-eye",
      isSapBusinessOne: false,
      EcommerceConfiguration: {
        Id: null,
        Name: null,
        Platform: null,
        User: null,
        Url: null,
        Password: null,
        GoogleCertified: null,
        Code: null,
        EcommerceStoreId: null,
        Active: true,
        Erp: {
          Id: null,
          Url: null,
          User: null,
          Password: null,
          Database: null,
          ConnectionString: null,
          Platform: null,
          UrlXS: null,
          UserXS: null,
          PasswordXS: null,
          UserRoter: null,
          PasswordRoter: null,
          UriRoter: null,
          SigurTrackerApiURL: null,
          SigurAuthURL: null,
          SigurAccessKey: null,
          SigurSecretKey: null,
          UpdatedAt: null,
          EcommerceId: null,
          SapClient: null,
          TokenPDV: null
        },
      }
    };
  },

  async mounted() {
    EventBus.$on("toggleRestrictedDataDisplay", async () => {
      await this.init();
      await this.AddLogToggleRestrictedDataDisplay();
    });

    await this.init();
    this.getErpPlatform();
  },

  methods: {
    init: async function () {
      let responseEcommerce = await this.$bahngleis.get(
        `Ecommerce/GetWithoutPassword/${this.$store.state.ecommerce.Id}?displayRestrictedData=${this.displayRestrictedData}`
      );

      this.EcommerceConfiguration = await responseEcommerce.data;

      this.dataLog = [];

      if (this.displayRestrictedData) {
        let responseLog = await this.$bahngleis.get(
          "/LogAction/getlogsofentity?identity=" +
          this.$store.state.ecommerce.Id +
          "&entity=Ecommerce"
        );

        this.dataLog = await responseLog.data;
      }
    },
    AddLogToggleRestrictedDataDisplay: async function () {
      if (this.displayRestrictedData) {
        await this.$bahngleis.post(`/LogAction/AddLogRestrictedData/${this.$store.state.ecommerce.Id}`);
      }
    },

    Save: async function () {
      if (!this.displayRestrictedData) {
        swal({
          title: "Ops!",
          text: "Não é possível atualizar quando os dados estão anonimizados.",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Ok',
          buttonsStyling: false
        });
        return;
      }

      var message = "";
      if (this.EcommerceConfiguration.Erp.Url == "")
        message = "Por favor, preencha o campo 'URL'";
      if (this.EcommerceConfiguration.Erp.Database == "")
        message = "Por favor, preencha o campo 'Banco de dados'";
      if (this.EcommerceConfiguration.Erp.User == "")
        message = "Por favor, preencha o campo 'Usuário'";
      if (this.EcommerceConfiguration.Url == "")
        message = "Por favor, preencha o campo 'URL'";
      if (this.EcommerceConfiguration.User == "")
        message = "Por favor, preencha o campo 'Usuário'";
      if (this.EcommerceConfiguration.Code == "")
        message = "Por favor, preencha o campo 'Code'";

      if (this.EcommerceConfiguration.Erp.Url.slice(-1) !== "/") {
        this.EcommerceConfiguration.Erp.Url = this.EcommerceConfiguration.Erp.Url + "/";
      }

      if (message != "") {
        this.ShowWarningNotification(message);
      } else {
        let promises = [];

        let identity = this.$store.state.ecommerce.Erp.Id + "-" +
          this.$store.state.ecommerce.Id;

        promises.push(this.saveEcommerce(identity));

        await Promise.all(promises);
        this.init();
      }
    },
    saveEcommerce: async function (identity) {
      await this.$bahngleis.put(`/Ecommerce`, this.EcommerceConfiguration);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 4000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
      return;
    },
    ShowSuccessNotification: async function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
      });
      return;
    },
    async testErpConnection() {
      const isFormValid = await this.$validator.validateAll();

      if (!isFormValid) return;

      if (this.isSapBusinessOne) {
        this.formatSapBusinessOneUrl();

        if (!this.validadeUrlErp() && this.displayRestrictedData) {
          this.ShowWarningNotification("URL parece estar fora do padrão de URL da Service Layer. Url deve terminar com 'b1s/v1/'");
          return;
        }

        await this.validateSapBusinessOneConnection();
      } else {
        await this.validateS4HanaConnection();
      }
    },
    formatSapBusinessOneUrl() {
      if (!this.EcommerceConfiguration.Erp.Url.endsWith("/")) {
        this.EcommerceConfiguration.Erp.Url += "/";
      }
    },
    async validateSapBusinessOneConnection() {
      try {
        await this.$bahngleis.get(`api/LoginERP/TesteLogin?ecommerceName=${this.ecommerce.Name}`);
        this.ShowSuccessNotification("Dados validados com sucesso");
      } catch (error) {
        this.$showError(error.response.data);
      }
    },
    async validateS4HanaConnection() {
      try {
        await this.$bahngleis.get(`api/LoginERP/HealthS4Hana?ecommerceName=${this.ecommerce.Name}`);
        this.ShowSuccessNotification("Dados validados com sucesso");
      } catch (error) {
        this.$showError(error.response.data);
      }
    },
    testXSodataConnection: async function () {
      let isValidateForm = await this.$validator.validateAll();

      if (isValidateForm) {
        await this.$bahngleis
          .get(`api/LoginERP/XSodata?ecommerceName=${this.ecommerce.Name}`)
          .then(() => {
            this.ShowSuccessNotification("Dados validados com sucesso");
          }).catch(error => {
            this.$showError(error.response.data)
          });
      }
    },
    testEcommerceConnection: async function () {

      var ecommerceRequest = {
        Name: this.ecommerce.Name,
        Company: this.$store.state.realm
      };

      await this.$bahngleis
        .post("api/Platform/validate-credentials", ecommerceRequest)
        .then(response => {
          let success = response.status === 200

          if (success) {
            this.ShowSuccessNotification("Credenciais de e-commerce validadas com sucesso");
          } else {
            this.ShowWarningNotification("Credenciais de e-commerce incorretas");
          }
        }).catch(() => {
          this.ShowWarningNotification("Erro ao validar as credenciais de e-commerce");
        });
    },
    validadeUrlErp() {
      let urlSplited = this.EcommerceConfiguration.Erp.Url.split("/");
      return (urlSplited.slice(-2)[0] == 'v1' && urlSplited.slice(-3)[0] == 'b1s');
    },
    getErpPlatform() {
      this.isSapBusinessOne = this.GetErpPlatformTypeByKey(this.ecommerce.Erp.Platform);
    },
    GetErpPlatformTypeByKey(key) {
      return ["Sap Hana", "Sap SQL", "Sap XML"].includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    },
    getPathImage() {
      let name = "bahn"; // Valor padrão
      if (this.ecommerce && this.ecommerce.Erp) {
        const platform = constants.ErpPlatform.find((p) => p.Value === this.ecommerce.Erp.Platform);
        if (platform) {
          name = this.getErpPlatformImageName(platform.Label);
        }
      }
      return require(`@/assets/images/${name}.png`);
    },
    getErpPlatformImageName(label) {
      const lowercaseLabel = label.toLowerCase();
      switch (lowercaseLabel) {
        case 'sap hana':
        case 'sap sql':
        case 'sap xml':
          return 'saphana';
        case 'sap s/4 hana':
          return 'saps4hana';
      }
    },
    urlFormatter(url) {
      if (!url) return null;

      const regex = /^(https?:\/\/[^\/]+)/;
      const match = url.match(regex);
      return match ? match[1] + '/sap/hana/xs/formLogin/login.html' : null;
    }
  },
};
</script>

<style>
.showPassword {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.icon-erp {
  width: 250px;
  height: 250px;
  margin-top: 20px;
}

.save-configuration {
  position: fixed;
  margin-left: 10px;
}

.custom-btn-primary,
.el-date-picker .el-picker-panel__footer .el-button,
.el-date-picker .el-time-panel.el-popper button,
.form-group .el-input-number .plus-button,
.form-group .el-input-number .el-input-number__increase,
.form-group .el-input-number .el-input-number__decrease {
  background: #00ADB7;
  background-image: -webkit-gradient(linear, right top, left bottom, from(#00ADB7), color-stop(#7DD7DC), to(#00ADB7));
  background-image: linear-gradient(to bottom left, #00ADB7, #7DD7DC, #00ADB7);
  background-color: #00ADB7;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-left: 10px;
}

.el-input__inner {
  background-color: #ffffff00;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #00ADB7;
  outline: 0;
}

.bold-label {
  font-weight: bold;
}
</style>
