var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$store.state.ecommerce && this.$store.state.ecommerce.Erp && this.$store.state.ecommerce.Erp.Id)?_c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(!this.$sidebar.isMinimized)?_c('label',{staticStyle:{"margin-left":"15px"}},[_vm._v("Tempo Real")]):_vm._e(),_c('sidebar-item',{attrs:{"link":{
         name: 'Dashboard',
         icon: 'tim-icons icon-chart-pie-36',
         path: '/Dashboard',
       }}}),(_vm.getUserPermission('29_r'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Pedidos',
         icon: 'tim-icons icon-cart',
         path: '/Orders',
       }}}):_vm._e(),(_vm.getUserPermission('11_r'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Fulfillment',
         icon: 'tim-icons icon-delivery-fast',
         path: '/Fulfillment',
       }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
         name: 'Produtos',
         icon: 'tim-icons icon-app',
         path: '',
         children: _vm.productsChildren,
       }}}),_c('sidebar-item',{attrs:{"menu":true,"link":{
         name: 'Histórico',
         icon: 'el-icon-notebook-2',
         path: '',
         children: _vm.historyChildren,
       }}}),(_vm.getUserPermission('04_r'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Ponte de Comando',
         icon: 'tim-icons icon-wifi',
         path: '/commandbridge',
       }}}):_vm._e(),(_vm.getUserPermission('00_c'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Financeiro',
         icon: 'tim-icons icon-money-coins',
         path: '',
         children: [
         {
             link: {
               name: 'Fiscal',
               icon: 'el-icon-money',
               path: '',
               children: [{
                 link: {
                   name: 'Tributos',
                   icon: 'tim-icons icon-coins',
                   path: '/Aliquots',
                 },
               }],
             },
           } ]
       }}}):_vm._e(),(!this.$sidebar.isMinimized)?_c('label',{staticStyle:{"margin-left":"15px","margin-top":"0.8em"}},[_vm._v("Configurações")]):_vm._e(),_c('sidebar-item',{attrs:{"menu":true,"link":{
         name: 'Definições de E-commerce',
         icon: 'el-icon-goods',
         path: '',
         children: _vm.definitionOfEcommerceChildren,
       }}}),_c('sidebar-item',{attrs:{"menu":true,"link":{
         name: 'Definições de Integrações',
         icon: 'tim-icons icon-link-72',
         path: '',
         children: _vm.integrationDefinitions,
       }}}),_c('sidebar-item',{attrs:{"menu":true,"link":{
         name: 'Definições de Serviços',
         icon: 'tim-icons icon-vector',
         path: '',
         children: _vm.notificationsChildren,
       }}}),(_vm.getUserPermission('00_c') && !this.$sidebar.isMinimized)?_c('label',{staticStyle:{"margin-left":"15px"}},[_vm._v("Administração")]):_vm._e(),(_vm.getUserPermission('00_c'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Grupos',
         icon: 'tim-icons icon-world',
         path: '/Group',
       }}}):_vm._e(),(_vm.getUserPermission('00_c'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Usuários',
         icon: 'tim-icons icon-single-02',
         path: '/User',
       }}}):_vm._e(),(_vm.getUserPermission('00_c'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Push Notification',
         icon: 'tim-icons icon-bell-55',
         path: '/PushNotification',
       }}}):_vm._e(),(_vm.getUserPermission('00_c'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Limpeza de Registros',
         icon: 'el-icon-document-delete',
         path: '/configuration/CleaningLog',
       }}}):_vm._e(),(_vm.getUserPermission('00_c'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Consulta Externa',
         icon: 'el-icon-search',
         path: '/configuration/ExternalSearch',
       }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-panel"},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[(_vm.renderComponent)?_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1):_vm._e()],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }