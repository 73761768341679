import Bahngleis from "@/apis/bahngleis";
const bahngleis = new Bahngleis();

const getFranchises = async ({ rootState, commit }, params) => {
  params = params || {};
  params = Object.assign(params, { ecommerceName: rootState.ecommerce.Name });
  return await bahngleis.get(`Franchise/GetPaginated`, { params: params }).then(response => {
    commit('SET_FRANCHISES', response.data.Items);
    return response;
  });
}

const getFranchisesByEcommerce = async ({ rootState, commit }, params) => {
  params = params || {};
  params = Object.assign(params, { ecommerceName: rootState.ecommerce.Name });
  return await bahngleis.get(`Franchise/GetByEcommerce`, { params: params }).then(response => {
    commit('SET_FRANCHISES', response.data);
    return response;
  });
}

const removeFranchise = async ({ }, id) => {
  return await bahngleis
    .delete(`/Franchise/${id}`)
    .then(res => { return Promise.resolve(res) })
    .catch(err => { return Promise.reject(err.response.data) });
}

const getFranchiseLog = async ({ commit }, id) => {
  return await bahngleis
    .get(`/LogAction/getlogsofentity?identity=${id}&entity=Franchise`)
    .then(response => {
      commit('SET_LOGS', response.data);
    });
}

const getWarehouses = async ({ rootState, commit }) => {
  return await bahngleis.get(`configuration/warehouse/GetAll`, { params: { ecommerceName: rootState.ecommerce.Name } }).then(response => {
    commit('SET_WAREHOUSES', response.data);
    return response;
  });
}

const saveFranchise = async ({ rootState }, payload) => {
  payload = Object.assign(payload, { ecommerceName: rootState.ecommerce.Name });
  return await bahngleis
    .post(`Franchise/`, payload);
}

const updateFranchise = async ({ }, payload) => {
  return await bahngleis
    .put(`Franchise/`, payload);
}

export default {
  getFranchises,
  getFranchisesByEcommerce,
  removeFranchise,
  saveFranchise,
  updateFranchise,
  getFranchiseLog,
  getWarehouses
}
