const franchisesToTable = state => {
  const franchises = state.franchises.sort((a, b) => a.Id - b.Id).map(franchise => {
    return {
      id: franchise.Id,
      name: franchise.Name,
      storeId: franchise.StoreId,
      sellerId: franchise.SellerId,
      active: franchise.Active
    };
  });

  return franchises;
};

const warehouseSelectField = state => {
  const warehouse = state.warehouses ? state.warehouses.sort((a, b) => a.Id - b.Id).map(warehouse => {
    return {
      Label: warehouse.WarehouseEcommerce,
      Value: warehouse.Id
    };
  }) : [];

  return warehouse;
}

export default {
  franchisesToTable,
  warehouseSelectField
}
