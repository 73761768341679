import actions from "./actions";
import mutations from './mutations';
import getters from "./getters";

const state = {
    fulfillments: [],
    fulfillmentCurrent: {
        Status: {
            BahnReference: null
        },
        Shipping: {
            Price: null,
            Address: {
                Street: null
            }
        },
        Customer: {
            Name: null
        },
        Payment: {
            Discount: null
        }
    },
    fulfillmentConfigurations: [],
    fulfillmentConfigurationsBP: [],
    logs: [],
    fulfillmentConfigurationCurrent: {}
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
