<template>
 <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }"
    v-if="this.$store.state.ecommerce && this.$store.state.ecommerce.Erp && this.$store.state.ecommerce.Erp.Id">
    <notifications></notifications>

    <!-- Side menu -->
    <side-bar>
      <template slot="links">
        <label v-if="!this.$sidebar.isMinimized" style="margin-left: 15px;">Tempo Real</label>
        <sidebar-item :link="{
          name: 'Dashboard',
          icon: 'tim-icons icon-chart-pie-36',
          path: '/Dashboard',
        }"></sidebar-item>
        <sidebar-item v-if="getUserPermission('29_r')" :link="{
          name: 'Pedidos',
          icon: 'tim-icons icon-cart',
          path: '/Orders',
        }"></sidebar-item>
        <sidebar-item v-if="getUserPermission('11_r')" :link="{
          name: 'Fulfillment',
          icon: 'tim-icons icon-delivery-fast',
          path: '/Fulfillment',
        }"></sidebar-item>
        <sidebar-item :link="{
          name: 'Produtos',
          icon: 'tim-icons icon-app',
          path: '',
          children: productsChildren,
        }" />
        <sidebar-item :menu="true" :link="{
          name: 'Histórico',
          icon: 'el-icon-notebook-2',
          path: '',
          children: historyChildren,
        }"></sidebar-item>
         <sidebar-item v-if="getUserPermission('04_r')" :link="{
          name: 'Ponte de Comando',
          icon: 'tim-icons icon-wifi',
          path: '/commandbridge',
        }"></sidebar-item>
         <sidebar-item v-if="getUserPermission('00_c')" :link="{
          name: 'Financeiro',
          icon: 'tim-icons icon-money-coins',
          path: '',
          children: [
          {
              link: {
                name: 'Fiscal',
                icon: 'el-icon-money',
                path: '',
                children: [{
                  link: {
                    name: 'Tributos',
                    icon: 'tim-icons icon-coins',
                    path: '/Aliquots',
                  },
                }],
              },
            },
          ]
        }"></sidebar-item>
        <label v-if="!this.$sidebar.isMinimized" style="margin-left: 15px;margin-top: 0.8em;">Configurações</label>
        <sidebar-item :menu="true" :link="{
          name: 'Definições de E-commerce',
          icon: 'el-icon-goods',
          path: '',
          children: definitionOfEcommerceChildren,
        }"></sidebar-item>
        <sidebar-item :menu="true" :link="{
          name: 'Definições de Integrações',
          icon: 'tim-icons icon-link-72',
          path: '',
          children: integrationDefinitions,
        }"></sidebar-item>
        <sidebar-item :menu="true" :link="{
          name: 'Definições de Serviços',
          icon: 'tim-icons icon-vector',
          path: '',
          children: notificationsChildren,
        }"></sidebar-item>
        <label  style="margin-left: 15px;" v-if="getUserPermission('00_c') && !this.$sidebar.isMinimized">Administração</label>
        <sidebar-item v-if="getUserPermission('00_c')" :link="{
          name: 'Grupos',
          icon: 'tim-icons icon-world',
          path: '/Group',
        }"></sidebar-item>
        <sidebar-item v-if="getUserPermission('00_c')" :link="{
          name: 'Usuários',
          icon: 'tim-icons icon-single-02',
          path: '/User',
        }"></sidebar-item>
        <sidebar-item v-if="getUserPermission('00_c')" :link="{
          name: 'Push Notification',
          icon: 'tim-icons icon-bell-55',
          path: '/PushNotification',
        }"></sidebar-item>
        <sidebar-item v-if="getUserPermission('00_c')" :link="{
          name: 'Limpeza de Registros',
          icon: 'el-icon-document-delete',
          path: '/configuration/CleaningLog',
        }"></sidebar-item>
        <sidebar-item v-if="getUserPermission('00_c')" :link="{
          name: 'Consulta Externa',
          icon: 'el-icon-search',
          path: '/configuration/ExternalSearch',
        }"></sidebar-item>
      </template>
    </side-bar>

    <!-- Main Panel -->
    <div class="main-panel">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition v-if="renderComponent" :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
import planMixin from "src/mixins/planMixin.js";
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import SidebarShare from "./Sidebar";

import { SimpleWizard, WizardTab } from "src/components";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import { SlideYDownTransition, ZoomCenterTransition } from "vue2-transitions";
import { EventBus } from "@/eventBus";
import childrens from '@/util/childrens';
import { mapActions, mapState } from "vuex";
import { link } from "d3";
import { path } from "d3";

export default {
  mixins: [planMixin],
  props: {
    platformId: {
      type: Number,
    },
  },
  data() {
    return {
      renderComponent: true,
      ecomErpPlatform: null,
      wizardModel: {
        erp: null,
        ecommerce: null,
        erpPlatform: null,
        ecommercePlatform: null,
      },
      step: "step1",
      mappingERPChildren: [],
      manageDocumentsChildren: [],
      definitionOfEcommerceChildren: [],
      productsChildren: [],
      historyChildren: [],
      integrationDefinitions: [],
      notificationsChildren: [],
      shippingModalities: false,
      brand: false,
      clientsGroup: false,
      address: false,
    };
  },
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    SidebarShare,
    SimpleWizard,
    WizardTab,
  },
  computed: {
    ...mapState(['ecommerce', 'realm'])
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidebar");
        initScrollbar("main-panel");
        initScrollbar("sidebar-wrapper");
        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
    validateStep(ref) {
      this.step = ref;
      return this.$refs[ref].validate(this.wizardModel);
    },
    onStepValidated(validated, model) {
      if (this.step === "step1") {
        this.wizardModel.erpPlatform = model;
      } else if (this.step === "step2") {
        this.wizardModel.erp = model;
      } else if (this.step === "step3") {
        this.wizardModel.ecommercePlatform = model;
      } else if (this.step === "step4") {
        this.wizardModel.ecommerce = model;
      }
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
    verifyPermission(node, permission) {
      if (permission) {
        return this.getUserPermission(node.permission);
      }
      if (!node.children) return false;
      for (const child of node.children) {
        let childPermission = this.verifyPermission(child, permission);
        if (childPermission) return true;
      }
      return false;
    },
    getChildrens() {
      this.definitionOfEcommerceChildren = childrens.DefinitionOfEcommerceChildren.filter(x => this.getUserPermission(x.permission));
      if(this.realm === "vulcabras")
        this.productsChildren = childrens.ProductsVulcabrasChildren.filter(x => this.getUserPermission(x.permission));
      else
        this.productsChildren = childrens.ProductsChildren.filter(x => this.getUserPermission(x.permission));
      this.manageDocumentsChildren = childrens.ManageDocumentsChildren.filter(x => this.getUserPermission(x.permission));
      this.historyChildren = childrens.HistoryChildren.filter(x => this.getUserPermission(x.permission));
      this.integrationDefinitions = childrens.IntegrationDefinitionsChildren.filter(x => {
        if (x.permission)
          return this.getUserPermission(x.permission);
      });
      this.notificationsChildren = childrens.NotificationsChildren.filter(x => this.getUserPermission(x.permission));
      this.mappingERPChildren = childrens.MappingERPChildren.filter(x => this.verifyPermission(x, x.permission));
      this.integrationDefinitions.forEach(a => {
        if (a.link.name === 'Mapeamentos ERP')
          a.link.children = this.mappingERPChildren;
        if (a.link.name === 'Documentos ERP')
          a.link.children = this.manageDocumentsChildren;
      });
    }
  },
  mounted: async function () {
    this.getChildrens();
    this.initScrollbar();
    EventBus.$on("changeEcommerce", () => this.forceRerender());
  },
};
</script>
<style lang="scss">
@import '~@/assets/sass/dashboard/custom/variables';


.card-wizard .nav-pills .nav-item .nav-link p {
  width: max-content;
}

$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

.modal-open {
  overflow: auto !important;
}
</style>
