export default {
  ConfigurationFieldType: {
    Combobox: 3,
    Toggle: 1,
    Text: 2,
    DatePicker: 4
  },
  Configurations: {
    BPL_IDAssignedToInvoice: "BPL_IDAssignedToInvoice",
    BilltoDefault: "BilltoDefault",
    CardAliasAndNameToUpperCase: "CardAliasAndNameToUpperCase",
    ChangeSkuProduct: "ChangeSkuProduct",
    ChangeWarehouseCode: "ChangeWarehouseCode",
    Comments: "Comments",
    Currency: "Currency",
    ERPCountryOrigin: "ERPCountryOrigin",
    ExecuteImageJob: "ExecuteImageJob",
    ExecutePriceJobProduct: "ExecutePriceJobProduct",
    ExecuteStockJobProduct: "ExecuteStockJobProduct",
    ExpenseCode: "ExpenseCode",
    FlagCep: "FlagCep",
    GeneratePickingBy: "GeneratePickingBy",
    GroupCode: "GroupCode",
    IntegrationPrice: "IntegrationPrice",
    ItemControlType: "ItemControlType",
    LanguageCode: "LanguageCode",
    LimitComplements: "LimitComplements",
    LimitEmail: "LimitEmail",
    MainUsage: "MainUsage",
    MultipleWarehouses: "MultipleWarehouses",
    OrderCancellationEcommerceOrigin: "OrderCancellationEcommerceOrigin",
    OrderCancellationEcommerceOriginOnlyStatus: "OrderCancellationEcommerceOriginOnlyStatus",
    OrderDraft: "OrderDraft",
    PriceListName: "PriceListName",
    PriceListNameB2B: "PriceListNameB2B",
    PriceListNameDiscount: "PriceListNameDiscount",
    SellerName: "SellerName",
    SendGroupPrices: "SendGroupPrices",
    Series: "Series",
    ShipToDefault: "ShipToDefault",
    ShowBranchColumnInOrderList: "ShowBranchColumnInOrderList",
    ShowCardOrderTransfers: "ShowCardOrderTransfers",
    ShowChannelColumnInOrderList: "ShowChannelColumnInOrderList",
    ShowCreateDateColumnInOrderList: "ShowCreateDateColumnInOrderList",
    ShowDocumentColumnInOrderList: "ShowDocumentColumnInOrderList",
    ShowImportDateColumnInOrderList: "ShowImportDateColumnInOrderList",
    ShowOrderItemTransfer: "ShowOrderItemTransfer",
    ShowWarehouseColumnInOrderList: "ShowWarehouseColumnInOrderList",
    ShowTargetERPColumnInOrderList: "ShowTargetERPColumnInOrderList",
    ShowWithoutStock: "ShowWithoutStock",
    SplitValueGift: "SplitValueGift",
    StockControlMethod: "StockControlMethod",
    StockUpdateForOrder: "StockUpdateForOrder",
    TaxFreightProductIntegrated: "TaxFreightProductIntegrated",
    TaxId1ForCorporateCustomer: "TaxId1ForCorporateCustomer",
    TaxId1ForIndividualCustomer: "TaxId1ForIndividualCustomer",
    TaxProductIntegrated: "TaxProductIntegrated",
    TrackingSourceName: "TrackingSourceName",
    TypeAllocationKit: "TypeAllocationKit",
    UseCustomFlowStockAndPrice: "UseCustomFlowStockAndPrice",
    UseNuuk: "UseNuuk",
    UserNordwareVirtualStock: "UserNordwareVirtualStock",
    WorkWithPositionsInSap: "WorkWithPositionsInSap",

  },
  AddressType: {
    Billing: 1,
    Shipping: 2
  },
  RecuringJobs: {
    SchedulePriceSynchronizer: "Sincronização de Preço",
    ScheduleStockSynchronizer: "Sincronização de Estoque"
  },
  CustomFieldType: [
    { Key: 0, Label: 'String' },
    { Key: 1, Label: 'Int' },
    { Key: 2, Label: 'Decimal' },
    { Key: 3, Label: 'Boolean' },
    { Key: 4, Label: 'DateTime' },
    { Key: 5, Label: 'Char' }
  ],
  CustomFieldDocumentType: [
    { Key: 0, Label: 'Pedido' },
    { Key: 1, Label: 'Parceiro de negócio' },
    { Key: 2, Label: 'Nota fiscal' },
    { Key: 3, Label: 'Contas a Receber' },
    { Key: 4, Label: 'Fulfillment - Dev. NFe Saída' },
    { Key: 5, Label: 'Adiantamento' },
    { Key: 6, Label: 'Recebimento de Mercadoria Transferência' },
    { Key: 7, Label: 'NFe Entrada de Transferência' },
    { Key: 8, Label: 'Contas a Pagar' }
  ],
  CustomFieldRule: [
    { Key: 0, Label: 'Nenhuma' },
    { Key: 1, Label: 'Somente números' },
    { Key: 2, Label: 'Remover espaços em branco' },
    { Key: 3, Label: 'Remover caracteres especiais' },
    { Key: 4, Label: 'Adicionar dias' },
    { Key: 5, Label: 'Máscara CPF/CNPJ' },
    { Key: 6, Label: 'Formatar data' },
    { Key: 7, Label: 'Alterar campos por DePara' }
  ],
  CustomFieldConditionType: [
    { Key: 0, Label: 'Igual' },
    { Key: 1, Label: 'Diferente' },
    { Key: 2, Label: 'Maior que' },
    { Key: 3, Label: 'Menor que' },
    { Key: 4, Label: 'Comprimento do texto igual a' },
    { Key: 5, Label: 'Comprimento do texto diferente de' },
    { Key: 6, Label: 'Comprimento do texto maior que' },
    { Key: 7, Label: 'Comprimento do texto menor que' },
    { Key: 8, Label: 'Nulo ou vazio' },
    { Key: 9, Label: 'Contém' },
    { Key: 10, Label: 'Não contém' },
    { Key: 11, Label: 'Dentro de' },
    { Key: 12, Label: 'Fora de' },
    { Key: 13, Label: 'Começa com' },
    { Key: 14, Label: 'Termina com' }
  ],
  OrderRuleConditionField: [
    { Key: '1', Label: 'SKU' },
    { Key: '2', Label: 'Preço' },
    { Key: '3', Label: 'Tipo de Cliente' },
    { Key: '4', Label: 'Estado' },
    { Key: '5', Label: 'Cidade' },
    { Key: '6', Label: 'Região' },
    { Key: '7', Label: 'Método de Envio' },
    { Key: '8', Label: 'Forma de pagamento' },
    { Key: '9', Label: 'Canal de Venda' },
    { Key: '10', Label: 'Depósito' },
    { Key: '11', Label: 'Valor limite para Faturamento' },
    { Key: '12', Label: 'Origem do produto' },
    { Key: '13', Label: '% de ICMS' },
    { Key: '14', Label: 'Interestadual' }
  ],
  OrderRuleRegion: [
    { Key: '0', Label: 'Sul' },
    { Key: '1', Label: 'Sudeste' },
    { Key: '2', Label: 'Centro-oeste' },
    { Key: '3', Label: 'Nordeste' },
    { Key: '4', Label: 'Norte' },
  ],
  OrderRuleTaxIdentification: [
    { Key: 'cpf', Label: 'CPF' },
    { Key: 'cnpjWithoutIE', Label: 'CNPJ sem IE' },
    { Key: 'cnpjWithIE', Label: 'CNPJ com IE' },
  ],
  BrazilianStates: [
    { Key: 'AC', Label: 'Acre' },
    { Key: 'AL', Label: 'Alagoas' },
    { Key: 'AP', Label: 'Amapá' },
    { Key: 'AM', Label: 'Amazonas' },
    { Key: 'BA', Label: 'Bahia' },
    { Key: 'CE', Label: 'Ceará' },
    { Key: 'DF', Label: 'Distrito Federal' },
    { Key: 'ES', Label: 'Espírito Santo' },
    { Key: 'GO', Label: 'Goiás' },
    { Key: 'MA', Label: 'Maranhão' },
    { Key: 'MT', Label: 'Mato Grosso' },
    { Key: 'MS', Label: 'Mato Grosso do Sul' },
    { Key: 'MG', Label: 'Minas Gerais' },
    { Key: 'PA', Label: 'Pará' },
    { Key: 'PB', Label: 'Paraíba' },
    { Key: 'PR', Label: 'Paraná' },
    { Key: 'PE', Label: 'Pernambuco' },
    { Key: 'PI', Label: 'Piauí' },
    { Key: 'RJ', Label: 'Rio de Janeiro' },
    { Key: 'RN', Label: 'Rio Grande do Norte' },
    { Key: 'RS', Label: 'Rio Grande do Sul' },
    { Key: 'RO', Label: 'Rondônia' },
    { Key: 'RR', Label: 'Roraima' },
    { Key: 'SC', Label: 'Santa Catarina' },
    { Key: 'SP', Label: 'São Paulo' },
    { Key: 'SE', Label: 'Sergipe' },
    { Key: 'TO', Label: 'Tocantins' },
  ],
  CustomFieldTypeRule: [
    { Key: 0, Label: 'Campo Customizado - Ecommerce para Erp' },
    { Key: 1, Label: 'Filial' },
    { Key: 2, Label: 'Utilização' },
    { Key: 3, Label: 'Depósito' },
    { Key: 4, Label: 'Método de Envio' },
    { Key: 5, Label: 'Forma de Pagamento' },
    { Key: 6, Label: 'Cálculo de Frete' },
    { Key: 7, Label: 'Cálculo para Crédito Presumido' },
    { Key: 8, Label: 'Campo Customizado - Bahn para Erp' },
  ],
  CustomFieldTaxZone: [
    { Key: "N", Label: 'Não' },
    { Key: "Y", Label: 'Sim' }
  ],
  CustomFieldOrigin:[
    { Key: 0, Label: 'Ecommerce para Erp' },
    { Key: 8, Label: 'Bahn para Erp' }
  ],
  NotificationEmailType: [
    { Key: 1, Label: 'Avisar ao ultrapassar X minutos sem pedidos integrados' },
    { Key: 2, Label: 'Avisar a cada pedido com erro' },
    { Key: 3, Label: 'Estoque' },
    { Key: 4, Label: 'Preço' },
    { Key: 5, Label: 'Pedido sem integração' },
    { Key: 6, Label: 'Comunicação com ERP' },
    { Key: 7, Label: 'Comunicação com XS Engine' },
    { Key: 8, Label: 'Pedidos cancelados' },
    { Key: 9, Label: 'Pedidos aguardando cancelamento' }
  ],
  Frequency: [
    { Key: '*/5 * * * *', Label: '5 minutos' },
    { Key: '*/10 * * * *', Label: '10 minutos' },
    { Key: '*/15 * * * *', Label: '15 minutos' },
    { Key: '*/30 * * * *', Label: '30 minutos' },
    { Key: '0 */1 * * *', Label: '1 hora' },
    { Key: '0 */2 * * *', Label: '2 horas' },
    { Key: '0 */3 * * *', Label: '3 horas' },
    { Key: '0 */4 * * *', Label: '4 hora' },
    { Key: '0 */5 * * *', Label: '5 horas' },
    { Key: '0 */6 * * *', Label: '6 horas' },
    { Key: '0 */7 * * *', Label: '7 hora' },
    { Key: '0 */8 * * *', Label: '8 horas' },
    { Key: '0 0 * * *', Label: 'Diário à meia-noite' },
  ],
  ObservationDocumentType: [
    { Key: 1, Label: 'Adiantamento' },
    { Key: 2, Label: 'Nota fiscal de Saída' },
    { Key: 3, Label: 'Contas a Receber' },
    { Key: 4, Label: 'Transferência - Recebimento de Mercadorias' },
    { Key: 5, Label: 'Transferência - Nota Fiscal de Entrada' },
    { Key: 6, Label: 'Transferência - Nota Fiscal de Saída' },
    { Key: 7, Label: 'Contas a Receber (Adiantamento)' },
    { Key: 8, Label: 'Pedido de Venda' },
    { Key: 11, Label: 'Fulfillment - Dev. Nota Fiscal de Saída' },
    { Key: 12, Label: 'Fulfillment - Nota Fiscal de Saída' }
  ],
  ObservationType: [
    { Key: 1, Label: 'Observação de abertura' },
    { Key: 2, Label: 'Observação de encerramento' },
    { Key: 3, Label: 'Observação do diário' },
    { Key: 4, Label: 'Observação do diário' },
    { Key: 5, Label: 'Observação' }
  ],
  ObservationTypeOptions: [
    { Key: 1, Label: 'Observação de abertura' },
    { Key: 2, Label: 'Observação de encerramento' },
    { Key: 3, Label: 'Observação do diário' },
    { Key: 5, Label: 'Observação' }
  ],
  StatusOptions: [
    { name: "Selecione", value: null },
    { name: "Ativo", value: "true" },
    { name: "Inativo", value: "false" },
  ],
  usageTypeOptions: [
    { Key: 0, Label: 'Nenhum' },
    { Key: 1, Label: 'Consumidor final CNPJ' },
    { Key: 2, Label: 'Consumidor final CPF' },
    { Key: 3, Label: 'Revenda' },
    { Key: 4, Label: 'Industrialização' },
    { Key: 5, Label: 'Revenda sem IE' },
    { Key: 6, Label: 'Brinde' },
    { Key: 7, Label: 'Venda' },
    { Key: 8, Label: 'Bônus' },
    { Key: 9, Label: 'Consumidor final CNPJ sem IE' },
    { Key: 10, Label: 'Devolução' },
    { Key: 11, Label: 'Brinde - Contribuinte' },
    { Key: 12, Label: 'Brinde - Não contribuinte' }
  ],
  taxOperationOptions: [
    { Key: 0, Label: 'Nenhum' },
    { Key: 1, Label: 'Remessa para envio' },
    { Key: 2, Label: 'Retorno para Transferência' },
    { Key: 3, Label: 'Remessa para Transferência' },
    { Key: 4, Label: 'Nota de Venda' },
    { Key: 5, Label: 'Retorno simbólico de venda' },
    { Key: 6, Label: 'Devolução de venda' },
    { Key: 7, Label: 'Remessa avulsa' },
    { Key: 8, Label: 'Retorno avulso' },
    { Key: 9, Label: 'Ajuste de Envio Excedente' },
    { Key: 10, Label: 'Ajuste de Envio Faltante' }
  ],
  fulfillmentConfigurationDocumentOptions: [
    { Key: 0, Label: 'Nenhum' },
    { Key: 8, Label: 'Pedido de Venda' },
    { Key: 9, Label: 'Transferência de estoque' },
    { Key: 11, Label: 'Dev. Nota Fiscal de Saída' },
    { Key: 12, Label: 'Nota Fiscal de Saída' },
    { Key: 13, Label: 'Contas a pagar' }
  ],
  fulfillmentConfigurationWarehouseOptions: [
    { Key: 0, Label: 'Não se aplica' },
    { Key: 1, Label: 'Origem' },
    { Key: 2, Label: 'Destino' }
  ],
  TaxOperation: [
    { description: "Remessa para envio", value: 1, class: "status statusScheduled" },
    { description: "Retorno para transferência", value: 2, class: "status statusTransferReturn" },
    { description: "Remessa para transferência", value: 3, class: "status statusTransferDelivery" },
    { description: "Nota de venda", value: 4, class: "status statusSalesNote" },
    { description: "Retorno simbólico de venda", value: 5, class: "status statusSymbolicReturnSales" },
    { description: "Devolução de venda", value: 6, class: "status statusSalesReturn" },
    { description: "Remessa avulsa", value: 7, class: "status statusSingleDelivery" },
    { description: "Retorno avulso", value: 8, class: "status statusSingleReturn" },
    { description: "Ajuste de Envio Excedente", value: 9, class: "status statusOvershipmentAdjustment" },
    { description: "Ajuste de Envio Faltante", value: 10, class: "status statusMissingShippingAdjustment" },
    { description: "Retirada de Estoque", value: 11, class: "status statusStockRemovel" }
  ],
  TrackingSourceName: [
    {
      value: '',
      label: "Nenhuma",
    },
    {
      value: 'U_NW_BAHNTRACKING',
      label: "Tabela de usuário SAP: U_NW_BAHNTRACKING",
    },
    {
      value: 'U_BCE_TRCK',
      label: "Tabela de usuário SAP: U_BCE_TRCK",
    },
    {
      value: 'ORDER_XS',
      label: "XSOData Customizada",
    },
    {
      value: 'EXTERNAL_API',
      label: "API Externa",
    },
    {
      value: 'SIGUR',
      label: "Sigur",
    }
  ],
  typeForm: {
    create: 'create',
    edition: 'edition'
  },
  AttributeType: {
    Product: 1,
    Sku: 2
  },
  AttributeValue: {
    Text: 1,
    Integer: 2,
    Decimal: 3
  },
  IntegrationStatus: {
    Pending: 0,
    Processing: 1,
    Completed: 2,
    Error: 9
  },
  CleaningLogType: [
    { Key: 1, Label: 'Logs de Estoque' },
    { Key: 2, Label: 'Logs de Preço' },
    { Key: 3, Label: 'Logs de Pedido' },
    { Key: 4, Label: 'Logs de Ação de Usuários' },
    { Key: 5, Label: 'Logs de Jobs Recorrentes' }
  ],
  StatusOrder: {
    Processing: "processing",
    Erro: "error",
    Integrated: "integrated",
    Complete: "complete",
    WaitingTransfer: "waitingTransfer",
    WaitingCancel: "waitingCancel",
  },
  costCenter: [
    { description: "Nenhum", value: '' },
    { description: "Código de custos", value: "CostingCode" },
    { description: "Código de custos 2", value: "CostingCode2" },
    { description: "Código de custos 3", value: "CostingCode3" },
    { description: "Código de custos 4", value: "CostingCode4" },
    { description: "Código de custos 5", value: "CostingCode5" },
    { description: "Código da regra de distribuição CPV", value: "COGSCostingCode" },
    { description: "Código da regra de distribuição CPV 2", value: "COGSCostingCode2" },
    { description: "Código da regra de distribuição CPV 3", value: "COGSCostingCode3" },
    { description: "Código da regra de distribuição CPV 4", value: "COGSCostingCode4" },
    { description: "Código da regra de distribuição CPV 5", value: "COGSCostingCode5" }
  ],
  CronOptions: [
    { Level: 1, Key: "*/5 * * * *", Label: "A cada 5 minutos" },
    { Level: 2, Key: "*/10 * * * *", Label: "A cada 10 minutos" },
    { Level: 3, Key: "*/15 * * * *", Label: "A cada 15 minutos" },
    { Level: 4, Key: "*/20 * * * *", Label: "A cada 20 minutos" },
    { Level: 5, Key: "*/25 * * * *", Label: "A cada 25 minutos" },
    { Level: 6, Key: "*/30 * * * *", Label: "A cada 30 minutos" },
    { Level: 7, Key: "*/45 * * * *", Label: "A cada 45 minutos" },
    { Level: 8, Key: "*/50 * * * *", Label: "A cada 50 minutos" },
    { Level: 9, Key: "0 */1 * * *", Label: "A cada 1 hora" },
    { Level: 10, Key: "0 */2 * * *", Label: "A cada 2 horas" },
    { Level: 11, Key: "0 */3 * * *", Label: "A cada 3 horas" },
    { Level: 12, Key: "0 */4 * * *", Label: "A cada 4 horas" },
    { Level: 13, Key: "0 */5 * * *", Label: "A cada 5 horas" },
    { Level: 14, Key: "0 */6 * * *", Label: "A cada 6 horas" },
    { Level: 15, Key: "0 */7 * * *", Label: "A cada 7 horas" },
    { Level: 16, Key: "0 */8 * * *", Label: "A cada 8 horas" },
    { Level: 17, Key: "0 7 * * *", Label: "Todo dia às 7:00h" },
    { Level: 18, Key: "0 8 * * *", Label: "Todo dia às 8:00h" },
    { Level: 19, Key: "0 10 * * *", Label: "Todo dia às 10:00h" },
    { Level: 20, Key: "0 12 * * *", Label: "Todo dia às 12:00h" },
    { Level: 21, Key: "0 14 * * *", Label: "Todo dia às 14:00h" },
    { Level: 22, Key: "0 16 * * *", Label: "Todo dia às 16:00h" },
    { Level: 23, Key: "0 18 * * *", Label: "Todo dia às 18:00h" },
    { Level: 24, Key: "0 20 * * *", Label: "Todo dia às 20:00h" },
    { Level: 25, Key: "0 0 * * *", Label: "Todo dia à meia-noite" }
  ],
  ExportProductEcommerceStatus: [
    { Key: 'all', Label: 'Todos' },
    { Key: 'active', Label: 'Ativo' },
    { Key: 'inactive', Label: 'Inativo' }
  ],
  ExportProductShopifyStatus: [
    { Key: 'all', Label: 'Todos' },
    { Key: 'archived', Label: 'Arquivado' },
    { Key: 'active', Label: 'Ativo' },
    { Key: 'draft', Label: 'Rascunho' }
  ],
  PaymentMethodTypes: [
    { Key: '', Label: 'Nenhum' },
    { Key: 'transfer', Label: 'Transferência' },
    { Key: 'creditCard', Label: 'Cartão de Crédito' }
  ],
  LogTypesStock: [
    { Key: 0, Label: "Todos" },
    { Key: 1, Label: "Captado no ERP" },
    { Key: 2, Label: "Enviado p/ Ecommerce" },
  ],
  LogTypesPrice: [
    { Key: null, Label: 'Todos' },
    { Key: false, Label: 'Não' },
    { Key: true, Label: 'Sim' }
  ],
  OrderType: {
    Sales: 0,
    Fulfillment: 2
  },
  TypeOfIntegrationLogs: [
    { Key: '', Label: 'Todos' },
    { Key: 'Error', Label: 'Erro' },
    { Key: 'Complete', Label: 'Completo' },
    { Key: 'Integrated', Label: 'Integrado' },
    { Key: 'Canceled', Label: 'Cancelado' },
    { Key: 'Started', Label: 'Iniciado' }
  ],
  AddonName: [
    { value: 'TAX4ALL', label: 'Tax4All (Invent)' },
    { value: 'SKILL', label: 'Triple One (Skill)' },
    { value: 'INVENT', label: 'TaxOne (Invent)' },
    { value: 'LAGOCONSULTORIA', label: 'B1Plus (Lago Consultoria)' },
    { value: '4TAXSEIDOR', label: '4Tax Seidor' },
  ],
  ItemControlTypes: [
    { Key: '0', Label: 'Nenhum' },
    { Key: '1', Label: 'Lote' }
  ],
  StockControlMethods: [
    { Key: '0', Label: 'Nenhum' },
    { Key: '1', Label: 'FIFO/PEPS' },
    { Key: '2', Label: 'LIFO/UEPS' },
    { Key: '3', Label: 'FEFO/PVPS' }
  ],
  IEDefaultValues: [
    { Key: '0', Label: 'Vazio' },
    { Key: '1', Label: 'Isento' }
  ],
  GeneratePickingBy: [
    { Key: '0', Label: 'Roter' },
    { Key: '1', Label: 'Bahn' }
  ],
  TypeAllocationKit: [
    { Key: 'AllocationByPercentage', Label: 'Pela porcentagem' },
    { Key: 'AllocationByPrice', Label: 'Pelo preço do item' }
  ],
  EcommercePlatForm: [
    { Value: 1, Label: 'Magento' },
    { Value: 2, Label: 'Magento 2' },
    { Value: 5, Label: 'Tray' },
    { Value: 6, Label: 'VTEX' },
    { Value: 7, Label: 'WooCommerce' },
    { Value: 8, Label: 'Platz' },
    { Value: 9, Label: 'Mercos' },
    { Value: 10, Label: 'Nuvemshop' },
    { Value: 11, Label: 'Loja Integrada' },
    { Value: 12, Label: 'Jetcommerce' },
    { Value: 13, Label: 'AnyMarket' },
    { Value: 14, Label: 'ExternalAPI' },
    { Value: 15, Label: 'Shopify' },
    { Value: 16, Label: 'TrayCorp' },
    { Value: 17, Label: 'Handel' },
    { Value: 18, Label: 'Bigcommerce' },
  ],
  ErpPlatform: [
    { Value: 1, Label: 'Sap Hana' },
    { Value: 2, Label: 'Sap SQL' },
    { Value: 3, Label: 'Sap XML' },
    { Value: 4, Label: 'Sap S/4 Hana' },
  ],
  IcotermsS4Hana: [
    { Value: 'CFR', Label: '(CFR) Custo e Frete' },
    { Value: 'CIF', Label: '(CIF) Custo, Seguro e Frete' },
    { Value: 'CPT', Label: '(CPT) Frete pago' },
    { Value: 'CIP', Label: '(CIP) Frete e seguro pagos' },
    { Value: 'DAF', Label: '(DAF) Entregue na Fronteira' },
    { Value: 'DAP', Label: '(DAP) Fornecido no local' },
    { Value: 'DAT', Label: '(DAT) Fornecido no Terminal' },
    { Value: 'DDP', Label: '(DDP) Entregue desalfandegado' },
    { Value: 'DDU', Label: '(DDU) Entregue sem desembaraço alfandegário' },
    { Value: 'DEQ', Label: '(DEQ) Entregue no cais (direitos pagos)' },
    { Value: 'DES', Label: '(DES) Entregue no navio' },
    { Value: 'DPU', Label: '(DPU) Fornecido no loc. descarregado' },
    { Value: 'EXW', Label: '(EXW) Na orgiem' }
  ],
  DeliveryType: [
    { Value: '0', Label: 'Nenhum' },
    { Value: '1', Label: 'Retirar na Loja' },
    { Value: '2', Label: 'Entrega ' }
  ],
  DeliveryManager: [
    { Value: '0', Label: 'Nenhum' },
    { Value: '1', Label: 'Entregue pela Loja' },
    { Value: '2', Label: 'Entregue pelo E-commerce' }
  ],
  ConditionOptions: [
    { value: 1, label: 'Não se aplica' },
    { value: 2, label: 'Código do Item' },
    { value: 3, label: 'Código CFOP' },
    { value: 4, label: 'Parceiro de Negócios' },
    { value: 5, label: 'Filial' },
    { value: 6, label: 'Utilização' }
  ],
  ConditionOptionsOrders: [
    { value: 1, label: 'Não se aplica' },
    { value: 2, label: 'Código do Item' },
    { value: 4, label: 'Parceiro de Negócios' }
  ],
  ConditionOptionsIncomingPayments: [
    { value: 1, label: 'Não se aplica' },
    { value: 4, label: 'Parceiro de Negócios' }
  ],
  ConditionTypeOptions: [
    { value: 0, label: '-' },
    { value: 1, label: 'Igual a' },
    { value: 2, label: 'Diferente de' }
  ],
  ToWhichOptions: [
    { value: 'erp', label: 'ERP' },
    { value: 'connector', label: 'Conector' },
    { value: 'xsEngine', label: 'XS Engine' },
    { value: 's4Hana', label: 'S4Hana' }
  ],
  ExternalAPI: 'EXTERNAL_API',
  InterstateOptions: [
    { Key: false, Label: 'Não' },
    { Key: true, Label: 'Sim' }
  ],
  TaxOperationType: {
    None: 0,
    DeliveryShipping: 1,
    ReturnToTransfer: 2,
    DeliveryTransfer: 3,
    SalesNote: 4,
    SymbolicReturnSales: 5,
    SalesReturn: 6,
    SingleDelivery: 7,
    SingleReturn: 8
  },
  S4HanaRoutes: [
    { Key: 'ItemPrice', Label: 'Preço do Item' },
    { Key: 'ItemStock', Label: 'Estoque do Item' },
    { Key: 'Order', Label: 'Pedido' }
  ],
  S4HanaRoutesEnum: {
    ItemPrice: 'ItemPrice',
    ItemStock: 'ItemStock',
    Order: 'Order'
  },
  TargetERP : {
    SAP: 0,
    PDV: 1,
  }
}
